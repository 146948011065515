import clsx from 'clsx';

import styles from './style.module.scss';

interface IEmptyTableViewProps {
  icon?: React.ReactNode;
  title?: string;
  caption?: string;
  className?: string;
}

const EmptyTableView: React.FC<IEmptyTableViewProps> = props => {
  const { icon, title, caption, className = '' } = props;

  return (
    <div className={clsx(styles['empty-view__wrapper'], className)}>
      {icon && <div className={styles['empty-view__icon-wrapper']}>{icon}</div>}
      {title && <h4 className={styles['empty-view__title']}>{title}</h4>}
      {caption && <span className={styles['empty-view__caption']}>{caption}</span>}
    </div>
  );
};

export default EmptyTableView;
