import { useClientEffect } from '@ping/hooks';
import { clsx } from 'clsx';
import React, { useRef } from 'react';
import { useRadioGroup } from 'react-aria';
import { useRadioGroupState } from 'react-stately';

import { ToggleButtonContext } from '../toggle-button.context';
import style from './style.module.scss';

import type IReactStately from 'react-stately';

interface IToggleButtonProps<T extends string> extends IReactStately.RadioGroupProps, ICustomizable {
  children?: React.ReactNode;
  onChange?: (value: T) => void;
  /** @default false */
  compact?: boolean;
}

export const ToggleButtonRoot = <T extends string>(props: IToggleButtonProps<T>) => {
  const state = useRadioGroupState(props);
  const { radioGroupProps } = useRadioGroup({ ...props, orientation: 'horizontal' }, state);
  const choiceListRef = useRef<HTMLDivElement>(null);

  //
  // It sets the dimensions of a selection indicator based on the current selected choice
  const setSelectionIndicatorDimensions: React.EffectCallback = () => {
    if (!choiceListRef.current) {
      return;
    }

    const activeChoice = choiceListRef.current.querySelector<HTMLLabelElement>('label[aria-current="true"]');
    if (!activeChoice) {
      return;
    }

    choiceListRef.current.style.setProperty('--button-group-selection-inline-size', activeChoice.offsetWidth + 'px');
    choiceListRef.current.style.setProperty('--button-group-selection-offset-x', activeChoice.offsetLeft + 'px');
  };

  //
  // It updates the dimensions of a selection indicator based on the browser's window size
  const updateSelectionIndicatorDimensions: React.EffectCallback = () => {
    window.addEventListener('resize', setSelectionIndicatorDimensions);

    return () => {
      window.removeEventListener('resize', setSelectionIndicatorDimensions);
    };
  };

  useClientEffect(setSelectionIndicatorDimensions, [state.selectedValue]);
  useClientEffect(updateSelectionIndicatorDimensions, []);

  return (
    <div {...radioGroupProps} className={clsx(style['toggle-button-root'], props.className)}>
      <ToggleButtonContext.Provider value={{ state, isCompact: props.compact }}>
        <div className={style['toggle-button-root__list']} ref={choiceListRef}>
          <div className={style['toggle-button-root__selection']} />
          {props.children}
        </div>
      </ToggleButtonContext.Provider>
    </div>
  );
};
