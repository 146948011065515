import { forwardRef, RefObject, useEffect } from 'react';
import useTable from '@ping/uikit/Table/useTable';
import useTableData from '@ping/uikit/Table/useTableData';
import useTableColumns from '@ping/uikit/Table/useTableColumns';
import TableElement from '@ping/uikit/Table/TableElement';

export const Table = forwardRef(
  (
    { data, isLoading, columns, onRowSelect, tableRef, manualSortBy, disableSortRemove, ...rest }: ITable,
    ref: RefObject<HTMLDivElement>
  ) => {
    const tableData = useTableData({ data, isLoading, ...rest });
    const tableColumns = useTableColumns({ columns, isLoading, ...rest });

    const { ...restUseTable } = useTable({
      data: tableData,
      columns: tableColumns,
      manualSortBy: manualSortBy,
      disableSortRemove: disableSortRemove,
      ...rest,
    });

    useEffect(() => {
      if (tableRef) {
        tableRef.current = restUseTable;
      }
    }, [restUseTable]);

    useEffect(() => {
      onRowSelect?.(restUseTable.selectedFlatRows);
    }, [restUseTable.selectedFlatRows]);

    return <TableElement ref={ref} isLoading={isLoading} {...restUseTable} {...rest} />;
  }
);
