import { clsx } from 'clsx';
import { useContext, useRef } from 'react';
import { mergeProps, useFocusRing, useRadio, VisuallyHidden } from 'react-aria';

import { ToggleButtonContext } from '../toggle-button.context';
import style from './style.module.scss';

import type IReactAria from 'react-aria';

interface IToggleButtonChoiceProps extends IReactAria.AriaRadioProps, ICustomizable {
  children?: React.ReactNode;
}

export const ToggleButtonChoice = (props: IToggleButtonChoiceProps) => {
  const context = useContext(ToggleButtonContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const { inputProps } = useRadio(props, context.state, inputRef);
  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <label
      className={clsx(style['toggle-button-choice'], props.className)}
      aria-current={!props.isDisabled && context.state.selectedValue === props.value}
      aria-disabled={props.isDisabled}
      data-focus-visible={isFocusVisible}
      data-compact={context.isCompact}
    >
      <VisuallyHidden>
        <input {...mergeProps(inputProps, focusProps)} ref={inputRef} />
      </VisuallyHidden>

      {props.children}
    </label>
  );
};
