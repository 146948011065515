import CircleInfoIcon from '@ping/assets/Icon/circle-info.svg';
import { ECurrency } from '@ping/enums';
import { Text, Tooltip } from '@ping/uikit';
import { format } from '@ping/utils';
import clsx from 'clsx';
import { ProgressBar as Bar } from 'react-bootstrap';
import { BeatLoader as Loading } from 'react-spinners';

import style from './style.module.scss';

// #TODO: Create a type (enum) for all cryptocurrencies in the project.

const CRYPTO = ['CTN'] as const;
type CryptoType = (typeof CRYPTO)[number];

interface IProps {
  title?: string;
  currentProgressValue: number;
  maxProgressValue: number;
  currency: ECurrency | CryptoType;
  isLoading?: boolean;
  isDisabled?: boolean;
  tooltipContent?: React.ReactNode;
  percentage?: boolean;
}

export const ProgressBar = (props: IProps) => {
  const {
    currentProgressValue,
    maxProgressValue,
    title,
    currency,
    isLoading,
    isDisabled = false,
    tooltipContent,
    percentage = false,
  } = props;

  const isCryptoType = ['CTN'].find(item => item === currency);
  const isFiatType = ECurrency[currency] as ECurrency;

  let currentValue = '';
  let maxValue = '';

  switch (currency) {
    case isCryptoType:
      currentValue = format.crypto(currentProgressValue, currency);
      maxValue = format.crypto(maxProgressValue, currency);
      break;
    case isFiatType:
      currentValue = format.fiatReverse(currency, currentProgressValue);
      maxValue = format.fiatReverse(currency, maxProgressValue);
      break;
    default:
      currentValue = format.number(currency);
      maxValue = format.number(currency);
      break;
  }

  if (isLoading) return <Loading color='white' size={10} />;

  const percentageFromLimit = Math.round((currentProgressValue / maxProgressValue) * 100);

  const unlimitedLimit = maxProgressValue >= Number.MAX_SAFE_INTEGER;

  const limitText = unlimitedLimit ? `${currency} Unlimited` : maxValue;

  const percentageText = percentage ? `(${percentageFromLimit || 0}%)` : '';

  return (
    <div className={clsx(style['progressBar'], { [style['progressBar_disabled']]: isDisabled })}>
      <div className={style['progressBar__title-container']}>
        {title && (
          <div className={style['progressBar__limits-info']}>
            <Text className={style['progressBar__text']} cadetBlueColor>
              {title}
            </Text>
            {tooltipContent && (
              <div>
                <Tooltip position='right' childrenIcon={<CircleInfoIcon className={style['progressBar__icon']} />}>
                  {tooltipContent}
                </Tooltip>
              </div>
            )}
          </div>
        )}
        <div className='d-flex'>
          <Text className={style['progressBar__text']}>{currentValue}</Text>
          <Text className={style['progressBar__text']} cadetBlueColor>
            &nbsp;{`/ ${limitText} ${percentageText}`}
          </Text>
        </div>
      </div>
      <Bar
        now={currentProgressValue}
        max={maxProgressValue}
        variant={percentageFromLimit >= 100 && 'danger'}
        className={style['progressBar__progress-bar']}
      />
    </div>
  );
};
