import { clsx } from 'clsx';

import style from './style.module.scss';

interface IDividerProps extends ICustomizable {
  vertically?: false;
}

export const Divider = (props: IDividerProps) => (
  <div className={clsx(style['divider'], props.className)} data-vertically={props.vertically} />
);
