import React from 'react';

import type IReactStately from 'react-stately';

interface IToggleButtonContext {
  state: IReactStately.RadioGroupState;
  isCompact?: boolean;
}

export const ToggleButtonContext = React.createContext<IToggleButtonContext>({
  state: undefined,
  isCompact: false,
});
