import React from 'react';
import clsx from 'clsx';
import { Text } from '@ping/uikit/Text';
import style from './style.module.scss';

interface IProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  className?: string;
  name?: string;
  groupDirection?: 'vertical' | 'horizontal';
  size?: 'regular' | 'small';
  small?: boolean;
  options: {
    view: React.ReactNode;
    value: string;
    disabled?: boolean;
  }[];
}

export const RadioInput = ({
  className,
  options,
  groupDirection = 'horizontal',
  small = false,
  value,
  name,
  size,
  ...rest
}: IProps) => {
  return (
    <div className={clsx(style['radio-wrapper'], style[groupDirection], className)}>
      {options.map(option => (
        <label
          key={option.value}
          className={clsx(style['radio-label'], {
            [style['disabled']]: option.disabled,
          })}
        >
          <input
            type='radio'
            value={option.value}
            checked={option.value === value}
            disabled={option.disabled}
            name={name}
            {...rest}
          />
          <span className={clsx(style['radio-mark'], { [style['radio-mark_small']]: size === 'small' || small })} />
          {option.view && (
            <Text body='regular' className={style['radio-text']}>
              {option.view}
            </Text>
          )}
        </label>
      ))}
    </div>
  );
};
