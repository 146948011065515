import MoreVerticalIcon from '@ping/assets/Icon/more-vertical.svg';
import { Text } from '@ping/uikit/Text';
import { Dropdown } from 'react-bootstrap';
import { DropdownItemProps } from 'react-bootstrap/DropdownItem';
import { clsx } from 'clsx';

import style from './style.module.scss';
import { ReactNode } from 'react';

type TDropDownItem = {
  title?: string;
  icon?: ReactNode;
  isDisabled?: boolean;
} & DropdownItemProps;

interface IProps {
  dropDownList?: TDropDownItem[];
  children?: React.ReactNode;
}

export const ActionTooltip = ({ children, dropDownList }: IProps) => {
  return (
    <Dropdown bsPrefix={style['action-tooltip__dropdown']}>
      <Dropdown.Toggle variant='link' bsPrefix={style['action-tooltip__toggle']}>
        <MoreVerticalIcon className={style['action-tooltip__icon']} />
      </Dropdown.Toggle>

      <Dropdown.Menu bsPrefix={style['action-tooltip__menu']}>
        {children && <div className={style['action-tooltip__content']}>{children}</div>}
        {dropDownList?.map((item, index) => {
          return (
            <Dropdown.Item
              key={`action-item-${item?.title || index}`}
              onClick={!item?.isDisabled && item?.onClick}
              disabled={item?.isDisabled}
              bsPrefix={clsx(style['action-tooltip__item'], {
                [style['action-tooltip__item-no-hover']]: item?.isDisabled,
              })}
            >
              <>
                {item?.icon && (
                  <div
                    className={clsx(style['action-tooltip__icon'], {
                      [style['action-tooltip__disabled']]: item?.isDisabled,
                    })}
                  >
                    {item?.icon}
                  </div>
                )}
                {item?.title && (
                  <Text body='regular' className={clsx({ [style['action-tooltip__disabled']]: item?.isDisabled })}>
                    {item.title}
                  </Text>
                )}
              </>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
